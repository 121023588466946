export default function dob(val = 0){
	if (typeof val === 'undefined') return '';

	var s = val.toString().replace(/\D/g, ''),
		mm = s.substring(0, 2),
		dd = s.substring(2, 4),
		yy = s.substring(4, 8),
		returns = [];

	if (s.length > 0) {
		returns.push(mm);
	}
	if (s.length > 2) {
		returns.push(dd);
	}
	if (s.length > 4) {
		returns.push(yy);
	}

	//			returns.push(mm,dd,yy);
	return returns.join('/');
}

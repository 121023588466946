/**
 * Vuex Module - Nav
 */

// Dependencies
import * as types from '@/lib/store/types'

// State
const state = {
	menus: {
		header: [

		],
		footer: [

		],
	},
}

// Actions
const actions = {}

// Mutations
const mutations = {}

// Getters
const getters = {
	menu: state => key => state.menus[key],
	menus: state => state.menus,
}

// Modules
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

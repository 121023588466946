/**
 * Vue Router - Routes
 */

// Dependencies
import { store } from '@/lib/store'

// Views
const Main = () => import(/* webpackChunkName: "main" */ '@/views/Main.vue')
const Wizard = () => import(/* webpackChunkName: "wizard" */ '@/views/Wizard.vue')

// Partials

// Components
const SiteHeader = () => import(/* webpackChunkName: "site" */ '@/components/SiteHeader.vue')
const SiteFooter = () => import(/* webpackChunkName: "site" */ '@/components/SiteFooter.vue')
const WizardHeader = () => import(/* webpackChunkName: "wizard" */ '@/components/WizardHeader.vue')
const WizardSummary = () => import(/* webpackChunkName: "wizard" */ '@/components/WizardSummary.vue')
const WizardProducts = () => import(/* webpackChunkName: "wizard" */ '@/components/WizardProducts.vue')
const WizardShipping = () => import(/* webpackChunkName: "shipping" */ '@/components/WizardShipping.vue')
const WizardShippingMethods = () => import(/* webpackChunkName: "shipping" */ '@/components/WizardShippingMethods.vue')
const WizardFinance = () => import(/* webpackChunkName: "finance" */ '@/components/WizardFinance.vue')
const WizardReview = () => import(/* webpackChunkName: "review" */ '@/components/WizardReview.vue')
const WizardCheckout = () => import(/* webpackChunkName: "review" */ '@/components/WizardCheckout.vue')
const WizardApplication = () => import(/* webpackChunkName: "application" */ '@/components/WizardApplication.vue')
const OrderDetails = () => import(/* webpackChunkName: "order" */ '@/components/OrderDetails.vue')
const ApplicationDetails = () => import(/* webpackChunkName: "application" */ '@/components/ApplicationDetails.vue')
const WizardLoading = () => import(/* webpackChunkName: "loading" */ '@/components/WizardLoading.vue')
const ErrorMessage = () => import(/* webpackChunkName: "error" */ '@/components/ErrorMessage.vue')
const KegCalculator = () => import(/* webpackChunkName: "calculator" */ '@/components/KegCalculator.vue')

// Functions
const wizardComponents = (content, modal) => ({
	sidebar: WizardSummary,
	contentHeader: WizardHeader,
	error: ErrorMessage,
	content,
	modal,
})

const mainComponents = (content, modal) => ({
	header: SiteHeader,
	footer: SiteFooter,
	error: ErrorMessage,
	content,
	modal,
})

const mainProps = () => ({
	errorMessage: store.getters['misc/error'],
})

const wizardProps = () => ({
	errorMessage: store.getters['cart/error'],
})

const stepCheck = async (to, next) => {
	const cart = store.getters['cart/cart']
	switch(to.name) {
		case 'step1':
			store.dispatch('cart/setCurrentStep', 1)
			next()
			break
		case 'step2':
			if (!cart.subTotal) {
				next({ name: 'step1' })
			} else {
				store.dispatch('cart/setCurrentStep', 2)
				next()
			}
			break
		case 'step3':
			if (!cart.subTotal || !cart.shipping || !cart.shipping.method) {
				next({ name: 'step2' })
			} else {
				store.dispatch('cart/setCurrentStep', 3)
				next()
			}
			break
		case 'step4':
			if (!cart.subTotal || !cart.shipping || !cart.shipping.method) {
				next({ name: 'step2' })
			} else {
				store.dispatch('cart/setCurrentStep', 4)
				next()
			}
			break
		default:
			next()
	}
}


// Export
export const routes = [
	{
		path: '/',
		component: Wizard,
		props: wizardProps,
		beforeEnter: (to, from, next) => {
			store.dispatch('cart/setStripeInstance')
			stepCheck(to, next)
		},
		children: [
			{
				name: 'loading',
				path: '',
				components: wizardComponents(WizardLoading),
			},
			{
				name: 'step1',
				path: 'purchase-kegs/1',
				components: wizardComponents(WizardProducts),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
					// next()
				},
			},
			{
				name: 'step2',
				path: 'purchase-kegs/2',
				components: wizardComponents(WizardShipping),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
				},
			},
			{
				name: 'step2shipping',
				path: 'purchase-kegs/2/shipping',
				components: wizardComponents(WizardShipping, WizardShippingMethods),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
				},
				meta: {
					modalVisible: true,
					modalTitle: 'Shipping Methods',
				},
			},
			{
				name: 'step3',
				path: 'purchase-kegs/3',
				components: wizardComponents(WizardFinance),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
				},
			},
			{
				name: 'step3application',
				path: 'purchase-kegs/3/application',
				components: wizardComponents(WizardApplication),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
				},
			},
			{
				name: 'step4',
				path: 'purchase-kegs/4',
				components: wizardComponents(WizardReview),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
				},
			},
			{
				name: 'checkout',
				path: 'purchase-kegs/4/checkout',
				components: wizardComponents(WizardReview, WizardCheckout),
				beforeEnter: async (to, from, next) => {
					try {
						await store.dispatch('cart/load')
					} catch (e) { }
					await stepCheck(to, next)
				},
				meta: {
					modalVisible: true,
					modalTitle: 'Payment Information',
				},
			},
		],
	},
	{
		component: Main,
		path: '',
		props: mainProps,
		children: [
			{
				name: 'order',
				path: 'order/:orderId',
				components: mainComponents(OrderDetails),
				props: mainProps,
				beforeEnter: (to, from, next) => {
					store.dispatch('cart/loadOrder', to.params.orderId)
						.then(next)
						.catch(err => {
							store.dispatch('misc/setError', err)
							next()
						})
				},
			},
			{
				name: 'application',
				path: 'application/:applicationId',
				components: mainComponents(ApplicationDetails),
				beforeEnter: (to, from, next) => {
					store.dispatch('cart/loadApplication', to.params.applicationId)
						.then(next)
						.catch(err => {
							store.dispatch('misc/setError', err)
							next()
						})
				},
			},
			{
				name: 'calculator',
				path: 'calculator',
				components: mainComponents(KegCalculator),

			},
		],
	},
]

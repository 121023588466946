export default function leaseType(type) {
	if (!type) return '';
	var output = type;

	switch (type) {
		case 'fml':
			output = 'Rental Program';
			break;
		case 'fl':
			output = 'Lease-to-Own Program';
			break;
	}
	return output;
}

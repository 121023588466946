/**
 * Vuex Module - Cart
 */
/* global Stripe */

// Dependencies
import * as types from '@/lib/store/types'
import { API } from '@/lib/api'
import { buildRefs, buildObject, buildArray, updateObject } from '@/lib/fb'

// Functions
const calculateLeasePayments = (cart, leases) => {
	// Calculate Lease Payments
	let lowest = {
		term: 0,
		amount: 10000000,
	}

	const leasePayments = {}
	leases.forEach(lease => {
		if (!leasePayments[lease.type]) {
			leasePayments[lease.type] = []
		}

		const amount = (cart.total * lease.lrf)
		const payment = {
			term: lease.term,
			amount,
		}
		lowest = amount < lowest.amount ? payment : lowest
		leasePayments[lease.type].push(payment)
	})

	return {
		leasePayments,
		lowest,
	}
}

const calculateProductDiscounts = (products) => {
	for (const key in products) {
		const product = products[key]
		let isDiscounted = false
		for (let i = 0; i < product.discounts.length; i++) {
			const discount = product.discounts[i]
			if (product.quantity > discount.quantity) {
				product.discountPrice = discount.price
				isDiscounted = true
			}
		}
		if (!isDiscounted) {
			delete products[key].discountPrice
		}
		products[key] = product
	}

	return products
}

// State
const state = {
	/** @type boolean */
	loading: false,
	error: null,
	stripe: null,
	cart: {
		products: {},
		subTotal: 0,
		taxes: 0.001,
		total: 0,
		totalQuantity: 0,
		shipping: {
			method: {},
			type: null,
		},
	},
	application: {
		owner: {},
		company: {},
	},
	order: {
		charge: {},
		products: {},
		shipping: {},
	},
	steps: [
		{
			label: 'Keg Options',
			title: 'Keg Options',
			prefix: '',
			status: false,
			err: false,
			link: {
				name: 'step1',
			},
		},
		{
			label: 'Shipping Method',
			title: 'Shipping Method',
			prefix: 'Next:',
			status: false,
			err: 'Please add some Kegs to your order',
			link: {
				name: 'step2',
			},
		},
		{
			label: 'Rental &amp; Leasing',
			title: 'Rental & Leasing',
			prefix: 'Next:',
			status: false,
			err: 'Please fill out required shipping information',
			link: {
				name: 'step3',
			},
		},
		{
			label: 'Buy Now',
			title: 'Order Summary',
			prefix: '',
			status: false,
			err: 'Please choose a shipping method',
			link: {
				name: 'step4',
			},
		},
	],
	currentStep: 1,
	leases: [],
	leaseRates: {
		fml: [
			{
				term: 36,
				lrf: 0.03798,
			},
			{
				term: 48,
				lrf: 0.03196,
			},
			{
				term: 60,
				lrf: 0.02875,
			},
		],
		fl: [
			{
				term: 36,
				lrf: 0.04395,
			},
			{
				term: 48,
				lrf: 0.03787,
			},
			{
				term: 60,
				lrf: 0.03448,
			},
		],
	},
	leasePayments: {
		fml: [],
		fl: [],
	},
	leaseLowest: {},
	shippingMethods: [],
}

/** @type import("vuex").ActionTree<typeof state> */
const actions = {
	async load({ commit }) {
		commit(types.SET_LOADING, true)
		const refs = await buildRefs()

		refs.leases.once('value')
			.then(snap => {
				const leases = buildArray(snap)
				if (leases) {
					commit(types.LOAD_LEASES, leases)
				}
			})

		refs.carts.on('child_changed', snap => {
			commit(types.UPDATE_CART, {
				key: snap.key,
				val: snap.val(),
			})
		})

		refs.carts.on('child_added', snap => {
			commit(types.UPDATE_CART, {
				key: snap.key,
				val: snap.val(),
			})
		})

		refs.carts.on('child_removed', snap => {
			commit(types.UPDATE_CART, {
				key: snap.key,
				val: null,
			})
		})

		const snap = await refs.carts.once('value')
		const cart = buildObject(snap)
		if (cart) {
			commit(types.LOAD_CART, cart)
		}
		commit(types.SET_LOADING, false)

		return cart
	},
	async addToCart({ state }, product) {
		const refs = await buildRefs()

		state.cart.products = state.cart.products || {}
		if (!state.cart.products[product.$key]) {
			state.cart.products[product.$key] = { ...product, quantity: 1 }
		} else {
			state.cart.products[product.$key].quantity = Number(state.cart.products[product.$key].quantity || 0) + 1
		}
		state.cart.shipping = { ...state.cart.shipping, method: null}
		state.cart._status = 'updating'
		state.cart.taxes = 0.001

		return updateObject(refs.carts, state.cart)
	},
	async removeFromCart({ state }, product) {
		if (!state.cart.products[product.$key]) return

		const refs = await buildRefs()

		state.cart.products[product.$key].quantity = Number(state.cart.products[product.$key].quantity) - 1
		if (!state.cart.products[product.$key].quantity) {
			delete state.cart.products[product.$key]
		}
		state.cart.shipping = { ...state.cart.shipping, method: null }
		state.cart._status = 'updating'
		state.cart.taxes = 0.001

		return updateObject(refs.carts, state.cart)
	},
	async updateQuantity({ state }, product) {
		product.quantity = Number(product.quantity) || 0
		if (product.quantity <= 0) {
			delete state.cart.products[product.$key]
		} else {
			state.cart.products[product.$key] = product
		}
		state.cart.shipping = { ...state.cart.shipping, method: null }
		state.cart._status = 'updating'
		state.cart.taxes = 0.001

		const refs = await buildRefs()

		return updateObject(refs.carts, state.cart)
	},
	async resetCart({ state }) {
		const refs = await buildRefs()

		state.cart.products = null
		state.cart.shipping = {}
		state.cart.subTotal = null
		state.cart.taxes = null
		state.cart.total = null
		state.cart.totalQuantity = null
		state.cart.finance = null
		state.cart._status = 'updating'

		return updateObject(refs.carts, state.cart)
	},
	async updateShipping({ state }, shipping) {
		const refs = await buildRefs()

		state.cart._status = 'updating'
		state.cart.shipping = shipping
		return updateObject(refs.carts, state.cart)
	},
	async updateFinancing({ state }, finance) {
		const refs = await buildRefs()

		state.cart._status = 'updating'
		state.cart.finance = finance
		return updateObject(refs.carts, state.cart)
	},
	loadShipping({ commit }, cart) {
		commit(types.SET_LOADING, true)
		commit(types.SET_ERROR, null)
		commit(types.LOAD_SHIPPING_METHODS, [])

		API.getCartShipping(cart.$key)
			.then(res => {
				commit(types.LOAD_SHIPPING_METHODS, res)
			})
			.catch(err => {
				commit(types.SET_ERROR, err)
			})
			.then(() => {
				commit(types.SET_LOADING, false)
			})
	},
	setCurrentStep({ commit }, step) {
		commit(types.SET_CURRENT_STEP, step)
	},
	createOrder({ commit }, data) {
		commit(types.SET_LOADING, true)
		commit(types.SET_ERROR, null)

		return new Promise((resolve, reject) => {
			API.stripeCreateToken(data.card, data.params)
				.then(({ token }) => {
					return API.createOrder(data.cartId, token.id)
				})
				.then(resolve)
				.catch(err => {
					commit(types.SET_ERROR, (err.error || err).message || err )
					reject((err.error || err).message || err)
				})
				.then(() => {
					commit(types.SET_LOADING, false)
				})
		})
	},
	async loadOrder({ commit }, orderId) {
		commit(types.SET_LOADING, true)
		const refs = await buildRefs()
		let order

		try {
			const snap = await refs.orders.child(orderId).once('value')
			order = buildObject(snap)
			if (order) {
				commit(types.LOAD_ORDER, order)
			} else {
				return commit(types.SET_ERROR, 'Can not load order data.')
			}
		} catch (e) {
			return commit(types.SET_ERROR, e)
		}

		commit(types.SET_LOADING, false)
		return order
	},
	async createApplication({ commit }, data) {
		commit(types.SET_LOADING, true)
		commit(types.SET_ERROR, null)

		const refs = await buildRefs()

		state.cart.application = data
		updateObject(refs.carts, state.cart)

		return new Promise((resolve, reject) => {
			API.createApplication(data.cartId, data)
				.then(res => {
					commit(types.LOAD_APPLICATION, res)
					resolve(res)
				})
				.catch(err => {
					commit(types.SET_ERROR, err)
					reject(err)
				})
				.then(() => {
					commit(types.SET_LOADING, false)
				})
		})
	},
	async loadApplication({ commit }, applicationId) {
		const refs = await buildRefs()

		return new Promise((resolve, reject) => {
			refs.applications
				.child(applicationId)
				.once('value')
				.then(snap => {
					const applications = buildObject(snap)
					if (applications) {
						commit(types.LOAD_APPLICATION, applications)
						resolve(applications)
					} else {
						commit(types.SET_ERROR, 'Can not load application')
					}
					reject()
				})
				.catch(() => {
					reject('Can not load application data.')
				})
		})

	},
	setStripeInstance({ commit }) {
		const setStripe = () => {
			if(typeof Stripe === 'undefined') {
				setTimeout(setStripe, 500)
			} else {
				const stripe = Stripe(process.env.VUE_APP_STRIPE_KEY)
				commit(types.SET_STRIPE_INSTANCE, stripe)
			}
		}
		setStripe()
	},
}

/** @type {import("vuex").MutationTree<typeof state>} */
const mutations = {
	[types.SET_LOADING](state, status) {
		state.loading = status
	},
	[types.SET_ERROR](state, error) {
		state.error = error
	},
	[types.LOAD_LEASES](state, leases) {
		state.leases = leases
	},
	[types.LOAD_CART](state, cart) {

		// Calculate Lease Payments
		// let lowest = {
		// 	term: 0,
		// 	amount: 10000000,
		// }

		// const leasePayments = {}
		// state.leases.forEach(lease => {
		// 	if (!leasePayments[lease.type]) {
		// 		leasePayments[lease.type] = []
		// 	}

		// 	const amount = (cart.total * lease.lrf)
		// 	const payment = {
		// 		term: lease.term,
		// 		amount,
		// 	}
		// 	lowest =  amount < lowest.amount ?  payment : lowest
		// 	leasePayments[lease.type].push(payment)
		// })

		cart.products = calculateProductDiscounts(cart.products)
		const { leasePayments, lowest } = calculateLeasePayments(cart, state.leases)
		state.leasePayments = leasePayments
		state.leaseLowest = lowest

		// Calculate Product Discounts
		// for (const key in state.cart.products) {
		// 	const product = state.cart.products[key]
		// 	let isDiscounted = false
		// 	for (let i = 0; i < product.discounts.length; i++) {
		// 		const discount = product.discounts[i]
		// 		if (product.quantity > discount.quantity) {
		// 			product.discountPrice = discount.price
		// 			isDiscounted = true
		// 		}
		// 	}
		// 	if (!isDiscounted) {
		// 		delete state.cart.products[key].discountPrice
		// 	}
		// 	state.cart.products[key] = product
		// }

		// Set Cart
		state.cart = cart
	},
	[types.NEW_CART](state, newCart) {
		state.cart = newCart
	},
	[types.LOAD_SHIPPING_METHODS](state, methods) {
		state.shippingMethods = methods
	},
	[types.SET_CURRENT_STEP](state, step) {
		state.currentStep = step
	},
	[types.SET_STRIPE_INSTANCE](state, stripe) {
		state.stripe = stripe
	},
	[types.UPDATE_CART](state, {key, val}) {
		state.cart[key] = val
		state.cart.products = calculateProductDiscounts(state.cart.products)
		const { leasePayments, lowest } = calculateLeasePayments(state.cart, state.leases)
		state.leasePayments = leasePayments
		state.leaseLowest = lowest
	},
	[types.LOAD_ORDER](state, order) {
		state.order = order
	},
	[types.LOAD_APPLICATION](state, application) {
		state.application = application
	},
}

// Getters
const getters = {
	loading: state => state.loading,
	error: state => state.error,
	steps: state => {
		if (state.cart.subTotal > 0) {
			state.steps[0].status = true
		} else {
			state.steps[0].status = false
		}

		if (state.cart.shipping && state.cart.shipping.method) {
			state.steps[1].status = true
			state.steps[2].status = true
		} else {
			state.steps[1].status = false
			state.steps[2].status = false
		}

		return state.steps
	},
	currentStep: state => state.currentStep,
	cart: state => state.cart,
	leasePayments: state => state.leasePayments,
	leaseLowest: state => state.leaseLowest,
	shippingMethods: state => state.shippingMethods,
	stripe: state => state.stripe,
	application: state => state.application,
	order: state => state.order,
}

// Module
export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

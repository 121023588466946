/**
 * Vue Router
 */

// Dependencies
import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'

// Install
Vue.use(VueRouter)

// Export
export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

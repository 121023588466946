/**
 * API Interface
 */

// Dependencies
import Axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import { store } from '@/lib/store'

// Config
Axios.defaults.baseURL = [process.env.VUE_APP_API_URL, process.env.VUE_APP_API_VERSION].join('/')
Axios.defaults.headers.common['Content-Type'] = 'application/json'

export const API = {
	init() {

	},

	getCartShipping(cartId) {
		const params = {
			method: 'get',
			url: `/cart/${cartId}/shipping`,
		}

		return this._request(params)
	},

	stripeCreateToken(card, params = {}) {
		const stripe = store.getters['cart/stripe']
		const token = stripe.createToken(card, params)
		return new Promise((resolve, reject) => {
			token.then(res => {
				if (res.error) {
					reject(res.error)
				} else {
					resolve(res)
				}
			})
		})
	},

	createOrder(cartId, token) {
		const params = {
			method: 'post',
			url: `/cart/${cartId}/checkout`,
			data: {
				stripeToken: token,
			},
		}

		return this._request(params)
	},

	createApplication(cartId, data) {
		const params = {
			method: 'post',
			url: `/cart/${cartId}/application`,
			data,
		}

		return this._request(params)
	},

	sendContact(data) {
		const params = {
			method: 'post',
			url: `/contact/website`,
			data,
		}

		return this._request(params)
	},

	_request(params) {
		const user = firebase.auth().currentUser

		return new Promise((resolve, reject) => {

			user.getIdToken()
				.then(token => {
					const headers = {
						Authorization: `Bearer ${token}`,
					}

					Axios({...params, headers})
						.then(r => r.data)
						.then(resolve)
						.catch(err => {
							reject(err.response.data.message)
						})
				})
				.catch(reject)

		})
	},
}

/**
 * Vuex Module - Products
 */

// Dependencies
import * as types from '@/lib/store/types'
import { buildRefs, buildObjects } from '@/lib/fb'
// import { productsRef, buildObjects } from '@/lib/fb'

const state = {
	loading: false,
	error: null,
	products: [],
}

const actions = {
	async load(context) {
		context.commit(types.SET_LOADING, true)
		const refs = await buildRefs()

		try {
			const snap = await refs.products.once('value')
			const res = buildObjects(snap)
			context.commit(types.LOAD_PRODUCTS, res)
			return res
		} catch (e) {
			context.commit(types.SET_ERROR, e)
		}
		context.commit(types.SET_LOADING, false)
	},
}

const mutations = {
	[types.SET_LOADING](state, status) {
		state.loading = status
	},
	[types.SET_ERROR](state, err) {
		state.error = err
	},
	[types.LOAD_PRODUCTS](state, res) {
		state.products = res
	},
}

const getters = {
	error: state => state.error,
	loading: state => state.loading,
	products: state=> state.products,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
}

/**
 * Vue Application
 */

// Dependencies
import Vue from 'vue';
import App from './App.vue';

// Library
import { router } from './lib/router';
import { store } from './lib/store'
import './registerServiceWorker'

// Plugins
import VueMeta from 'vue-meta'
Vue.use(VueMeta)
import filters from './lib/filters'
Vue.use(filters)

// Config
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

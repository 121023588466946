/**
 * Firebase Library
 */

// Dependencies
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

export const db = firebase.database()

// Refs
// export const applicationsRef = firebase.database().ref('applications')
// export const ordersRef = firebase.database().ref('orders')
// export const productsRef = firebase.database().ref('products')
// export const cartsRef = () => {
// 	const { uid } = firebase.auth().currentUser
// 	return firebase.database().ref('carts').child(uid).child(uid)
// }
// export const leasesRef = firebase.database().ref('leases')

export const loginUser = () => {
	return new Promise((resolve, reject) => {
		firebase.auth().onAuthStateChanged((user) => {
			if (user) {
				resolve(user)
			} else {
				firebase.auth().signInAnonymously()
					.then(res => {
						resolve(res.user)
					})
					.catch(reject)
			}
		})
	})
}

export async function buildRefs() {

	let user = await firebase.auth().currentUser
	if (!user) {
		try {
			user = await loginUser()
		} catch (e) {
			throw e
		}
	}

	const { uid } = user

	return {
		applications: firebase.database().ref('applications'),
		orders: firebase.database().ref('orders'),
		products: firebase.database().ref('products'),
		leases: firebase.database().ref('leases'),
		carts: firebase.database().ref('carts').child(uid).child(uid),
	}
}

// Helpers
export const buildObject = snap => {
	const val = snap.val()
	if (!val) return
	const $ref = snap.ref.toString()
	const $key = snap.key
	const $priority = snap.priority

	return Object.assign(val, {$ref, $key, $priority})
}

export const buildObjects = snap => {
	const res = {}
	snap.forEach(child => {
		const obj = buildObject(child)
		res[obj.$key] = obj
	})

	return res
}

export const buildArray = snap => {
	const res = []
	snap.forEach(child => {
		res.push(buildObject(child))
	})
	return res
}

export const prepArray = data => {
	const res = []
	for (let i = 0; i < data.length; i++) {
		res[i] = prepObject(data[i])
	}
	return res
}

export const prepObject = data => {
	const res = {}
	for (const key in data) {
		const d = data[key]
		if (key.indexOf('$') !== 0) {
			if (Array.isArray(d)) {
				res[key] = prepArray(d)
			} else if (typeof d === 'object') {
				res[key] = prepObject(d)
			} else if (d) {
				res[key] = d
			}
		}
	}

	return res
}

export const addObject = (ref, data) => {

	const addRef = ref.push()
	const addKey = addRef.key()
	const priority = data.$priority || firebase.database.ServerValue.TIMESTAMP
	const toAdd = prepObject(data)

	toAdd.created_at = firebase.database.ServerValue.TIMESTAMP

	return new Promise((resolve, reject) => {
		addRef.setWithPriority(toAdd, priority)
			.then(() => {
				toAdd.$key = addKey
				resolve(toAdd)
			})
			.catch(reject)
	})
}

export const updateObject = (ref, data) => {

	const priority = data.$priority || firebase.database.ServerValue.TIMESTAMP
	const toUpdate = prepObject(data)
	toUpdate.updated_at = firebase.database.ServerValue.TIMESTAMP
	if (!data.created_at) {
		toUpdate.created_at = firebase.database.ServerValue.TIMESTAMP
	}

	return new Promise((resolve, reject) => {
		ref.setWithPriority(toUpdate, priority)
			.then(() => {
				resolve(toUpdate)
			})
			.catch(reject)
	})
}

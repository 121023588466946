/**
 * Vue Filter - PHone
 */

/**
 * Convert number to phone format.
 *
 * @param {number} val Number value
 */
export default function phone(val = 0) {
	var s = val.toString().replace(/\D/g, ''),
		area = s.substring(0, 3),
		front = s.substring(3, 6),
		end = s.substring(6, 10),
		returns = [];

	s = s.substring(0, 10);

	if (s.length > 0 && s.length <= 3) {
		returns.push(area);
	}
	if (s.length > 3) {
		returns.push('(' + area + ')');
		returns.push(' ' + front);
	}
	if (s.length > 6) {
		returns.push('-' + end);
	}

	return returns.join('');
}

/**
 * Firebase-based Application Config
 */

// Dependencies

// Functions
const fromJson = data => {
	try {
		JSON.parse(data)
	} catch (e) {
		return data
	}
	return JSON.parse(data)
}

// Configs
export const FIREBASE = fromJson(process.env.FIREBASE_CONFIG || process.env.VUE_APP_FIREBASE_CONFIG)

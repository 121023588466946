<template>
	<router-view id="app"></router-view>
</template>

<script>
/* global ga */
// Dependencies
import firebase from 'firebase/app'
import { FIREBASE } from './config'

// Firebase
firebase.initializeApp(FIREBASE)

// Component
export default {
	name: 'app',
	mounted() {
		if (typeof ga !== 'undefined') {
			ga('require', 'ec')
		}
	},
	metaInfo: () => ({
		title: 'Buy, Rent & Lease Stainless Steel Kegs',
		titleTemplate: '%s | InstantKegs',
		meta: [
			{
				charset: 'utf-8',
			},
			{
				'http-equiv': 'X-UA-Compatible',
				content: 'IE-edge',
			},
			{
				name: 'description',
				content: 'Get your kegs quick and easy with our one of a kind keg buying wizard that walks your through selecting kegs, comparing freight shipping and purchasing instantly.',
			},
			{
				name: 'viewport',
				content: 'user-scalable=no,initial-scale=1,maximum-scale=1,minimum-scale=1,width=device-width,minimal-ui',
			},
		],
		link: [
			{
				rel: 'stylesheet',
				href: '//fonts.googleapis.com/css?family=Oswald:400,700,300|Open+Sans:400,300,600,700,800,400italic',
			},
		],
		script: [
			{
				src: '//js.stripe.com/v3/',
			},
			{
				type: 'text/javascript',
				innerHTML: `setTimeout(function(){var a=document.createElement("script");
	var b=document.getElementsByTagName("script")[0];
	a.src="//script.crazyegg.com/pages/scripts/0040/9498.js?"+Math.floor(new Date().getTime()/3600000);
	a.async=true;a.type="text/javascript";b.parentNode.insertBefore(a,b)}, 1);`,
			},
		],
	}),
}
</script>

<style lang="scss">
// @import "~bootstrap/scss/bootstrap";
@import 'styles/partials';
#app {
	background: color_('white','medium');
}
</style>

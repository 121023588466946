export const SET_LOADING = 'SET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const LOAD_PRODUCTS = 'LOAD_PRODUCTS'
export const SET_PRODUCT = 'SET_PRODUCT'

export const LOAD_CART = 'LOAD_CART'
export const NEW_CART = 'NEW_CART'
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_QUANTITY = 'UPDATE_QUANTITY'
export const UPDATE_CART = 'UPDATE_CART'
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const LOAD_SHIPPING_METHODS = 'LOAD_SHIPPING_METHODS'
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP'
export const SET_STRIPE_INSTANCE = 'SET_STRIPE_INSTANCE'

export const LOAD_LEASES = 'LOAD_LEASES'

export const LOAD_ORDER = 'LOAD_ORDER'

export const LOAD_APPLICATION = 'LOAD_APPLICATION'
